const projects = [
  {
    id: 19,
    title: "Spotify Stats",
    year: "2021",
    body:
      "In the summer of 2021, I created a site that I envisioned long before I knew how to properly code. Music has always been a huge part of my life and as a long-time Spotify user, I wished there was a better way to track my listening habits. Thus, I created this simple site that allows Spotify Premium members to see their top artists and songs for the past month, half-year, and the entirety of their account.",
    linkURL: "https://spotify.zachizzard.com",
    linkText: "spotify.zachizzard.com",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Full-Stack Development",
    tags: ["Front-End Development", "Back-End Development", "Personal Project"],
    images: [
      require("./portfolio_data/8_spotify/0.jpg"),
      require("./portfolio_data/8_spotify/1.jpg"),
    ],
  },
  {
    id: 18,
    title: "Learn To Code",
    year: "2020",
    body:
      "For the final project of my Design II course, I was given the chance to create a guide to help someone learn a new skill in the global pandemic.  Inspired by a classmate telling me I helped them understand CSS for the first time, I created a guide to help people learn the basics of web development. Throughout I leave helpful tips and tricks that I have picked up throughout my life as a web developer.",
    linkURL: "https://learntocode.zachizzard.com",
    linkText: "learntocode.zachizzard.com",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Web Design",
    tags: ["Front-End Development", "DES 202", "Design Course"],
    images: [
      require("./portfolio_data/13_learn_to_code/0.jpg"),
      require("./portfolio_data/13_learn_to_code/1.jpg"),
      require("./portfolio_data/13_learn_to_code/2.jpg"),
    ],
  },
  {
    id: 17,
    title: "Attorney-Client Privilege DB",
    year: "2020",
    body:
      "For my senior capstone course, my group mates and I worked with the Los Angeles County District Attorney's Office to create a database and corresponding user interface to protect an individual's right to private communications with their lawyer. Users were able to upload a CSV containing state-wide lawyer contact information which could then be searched by email or phone number. On this project, I led the front-end development and worked on the back-end to create the communication pipeline between the UI and database.",
    linkURL: "https://github.com/shreyahavaldar/ATClipper",
    linkText: "GitHub Repository",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Full-Stack Development",
    tags: [
      "Front-End Development",
      "Back-End Development",
      "CSCI 401",
      "Computer Science Course",
    ],
    images: [
      require("./portfolio_data/9_atclipper/0.jpg"),
      require("./portfolio_data/9_atclipper/1.jpg"),
    ],
  },
  {
    id: 16,
    title: "Perigo",
    year: "2018",
    body:
      "To help USC students better exchange goods with their classmates, my group mates and I created Perigo during our Software Design course. I lead the front-end development for this project and helped to create the Firebase CMS for image uploads.",
    linkURL: "https://github.com/hariria/perigo",
    linkText: "GitHub Repository",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Full-Stack Development",
    tags: [
      "Front-End Development",
      "Back-End Development",
      "CSCI 201",
      "Computer Science Course",
    ],
    images: [
      require("./portfolio_data/1_perigo/0.jpg"),
      require("./portfolio_data/1_perigo/1.jpg"),
    ],
  },
  {
    id: 15,
    title: "Bitmark Node App",
    year: "2018",
    body:
      "In the summer of 2018, I worked at Bitmark, Inc. a company that uses blockchain technology to create secure and verifiable transactions. I worked on their Bitmark Node team and created a desktop user interface for their blockchain user endpoint. Previously, all communication with the blockchain occurred through a docker container, which was very tedious to set up and often error-prone. This software enabled an end-user to easily connect with the system and monitor its progress.",
    linkURL: "https://github.com/bitmark-inc/bitmark-node-app",
    linkText: "GitHub Repository",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Full-Stack Development",
    tags: ["Front-End Development", "Back-End Development", "Internship"],
    images: [require("./portfolio_data/0_bitmark_node/0.jpg")],
  },
  {
    id: 14,
    title: "Sienna - Randi Cover",
    year: "2021",
    body:
      "In early 2021, I was asked to design the cover for Randi’s first-ever single. The cover itself was inspired by listening to the song and pulling in references from the singer’s life. Additionally, I generated a video to be posted to Instagram stories to promote the song before its release.",
    linkURL: "https://open.spotify.com/album/7IP7AWnX1yQ0hqB4ENe4Ks",
    linkText: "Spotify",
    linkType: "href",
    secondaryLinkURL: "https://photos.app.goo.gl/s2itZtJm2QGd8F7t7",
    secondaryLinkText: "Instagram Story Ad (Video)",
    secondaryLinkType: "sienna_mp4",
    category: "Album Art",
    tags: ["Photoshop", "After Effects", "Album Art", "Personal Project"],
    images: [require("./portfolio_data/19_sienna_single_cover/0.jpg")],
  },
  {
    id: 13,
    title: "Fantasy Meets Reality",
    year: "2021",
    body:
      "This series of four photos are the result of a month-long experimentation with color. Inspired by an accidental image editing glitch, I delved into the limits of Photoshop, attempting to blur the lines of reality and fantasy. Eventually, I turned this bug into a reproducible process that allows me to translate the fantasy landscapes I dream into the real world.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Photoshop",
    tags: ["Photoshop", "Personal Project"],
    images: [
      require("./portfolio_data/18_fantasy_meets_reality/0.jpg"),
      require("./portfolio_data/18_fantasy_meets_reality/1.jpg"),
      require("./portfolio_data/18_fantasy_meets_reality/2.jpg"),
      require("./portfolio_data/18_fantasy_meets_reality/3.jpg"),
    ],
  },
  {
    id: 12,
    title: "LA Metro Rebrand",
    year: "2021",
    body:
      "In my Design III course, I was tasked with modifying an existing brand to better fit the modern world. For this project, I wanted to redesign the LA Metro to better serve the city having read a study about that women and non-binary people are impacted by the current metro. The ideation phase of this project centered around policy changes to make the metro safer, while the design phase focused on communicating these changes to the LA community in Metro’s existing branding language.",
    linkURL: "metro_pdf",
    linkText: "Full Project (PDF)",
    linkType: "metro_pdf",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Branding",
    tags: [
      "Illustrator",
      "Branding",
      "Social Design",
      "DES 302",
      "Design Course",
    ],
    images: [
      require("./portfolio_data/17_la_metro_rebrand/0.jpg"),
      require("./portfolio_data/17_la_metro_rebrand/1.jpg"),
      require("./portfolio_data/17_la_metro_rebrand/2.jpg"),
      require("./portfolio_data/17_la_metro_rebrand/3.jpg"),
      require("./portfolio_data/17_la_metro_rebrand/4.jpg"),
    ],
  },
  {
    id: 11,
    title: "Roots Restaurant Line",
    year: "2021",
    body:
      "For my first Design III project, I redesigned the line outside my favorite hometown restaurant Roots. Throughout the COVID-19 pandemic, Roots has struggled with properly managing its order pick-up, often causing customers to wait for long periods of time within close quarters of other customers. In this project, I introduce a series of solutions to better the line waiting experience and allow customers to give back while waiting.",
    linkURL: "roots_pdf",
    linkText: "Full Project (PDF)",
    linkType: "roots_pdf",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Branding",
    tags: [
      "Illustrator",
      "Branding",
      "Social Design",
      "DES 302",
      "Design Course",
    ],
    images: [
      require("./portfolio_data/16_roots_line/0.jpg"),
      require("./portfolio_data/16_roots_line/1.jpg"),
      require("./portfolio_data/16_roots_line/2.jpg"),
      require("./portfolio_data/16_roots_line/3.jpg"),
    ],
  },
  {
    id: 10,
    title: "Center Earth - Fashion Brand",
    year: "2020",
    body:
      "In the second half of my Graphic Design in Fashion course, I had the chance to develop my own brand and the first collection. Center Earth focused on creating high-quality and sustainable clothing, with a heavy emphasis on screen-printed designs. At the end of the project, I produced both a lookbook and brand advertising materials, along with a screen-printed sample t-shirt.",
    linkURL: "ce_lookbook_pdf",
    linkText: "F/W 2020 Lookbook (PDF)",
    linkType: "ce_lookbook_pdf",
    secondaryLinkURL: "ce_project_pdf",
    secondaryLinkText: "Project Presentation (PDF)",
    secondaryLinkType: "ce_project_pdf",
    category: "Fashion Design",
    tags: [
      "Illustrator",
      "Photoshop",
      "Fashion Design",
      "DES 313",
      "Design Course",
    ],
    images: [
      require("./portfolio_data/15_center_earth/0.jpg"),
      require("./portfolio_data/15_center_earth/1.jpg"),
    ],
  },
  {
    id: 9,
    title: "Anna Sui Rebrand",
    year: "2020",
    body:
      "I was tasked with re-designing Anna Sui’s eponymous brand in the first half of my Graphic Design in Fashion course. In doing so, I had the chance to explore fashion illustration and pattern making while learning the basics of the fashion world. In the process, I created a series of sweatsuits, sunglasses, perfume, some all-over prints, and other brand collateral.",
    linkURL: "as_project_pdf",
    linkText: "Full Project (PDF)",
    linkType: "as_project_pdf",
    secondaryLinkURL: "as_instagram_mp4",
    secondaryLinkText: "Instagram Ad (Video)",
    secondaryLinkType: "as_instagram_mp4",
    category: "Fashion Design",
    tags: [
      "Illustrator",
      "Photoshop",
      "Fashion Design",
      "DES 313",
      "Design Course",
    ],
    images: [
      require("./portfolio_data/14_anna_sui/0.jpg"),
      require("./portfolio_data/14_anna_sui/1.jpg"),
    ],
  },
  {
    id: 8,
    title: "2020 Voting Posters",
    year: "2020",
    body:
      'In my Design II course, I was given the chance to create a poster to encourage people to vote in the 2020 election. The first poster was intended to be a risograph print, however, I was unable to get access to my university’s printer due to the pandemic. This poster focused on the unnecessary death toll of the pandemic visualized through a heat-map that I generated in Python. For the second poster, we had to redesign the first poster in a randomly assigned genre for which I was given "time-travel."',
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Poster Design",
    tags: [
      "Photoshop",
      "Social Design",
      "Poster Design",
      "DES 202",
      "Design Course",
    ],
    images: [
      require("./portfolio_data/12_2020_vote/0.jpg"),
      require("./portfolio_data/12_2020_vote/1.jpg"),
    ],
  },
  {
    id: 7,
    title: "Emma's Movie Cafe",
    year: "2020",
    body:
      "For the first major project of my Design II class, I had to create a brand for another student in my class. My partner Emma emphasized her love for classic movies and tea, which resulted in Emma’s Movie Cafe, a redesigned movie theater for the COVID-19 pandemic. Each movie theater box allowed for a family to order food & drink and watch both new and classic movies in a safe environment.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Branding",
    tags: ["Illustrator", "Branding", "DES 202", "Design Course"],
    images: [
      require("./portfolio_data/11_emma_movie_cafe/0.jpg"),
      require("./portfolio_data/11_emma_movie_cafe/1.jpg"),
    ],
  },
  {
    id: 6,
    title: "Number Set Design",
    year: "2020",
    body:
      "Inspired by the Walker Art Museum typeface, I was tasked with creating a modular numeric typeface in my Typography II class. The base form is a series of hand-drawn numbers created from the style of bubble letters that I have been doodling my entire life. The remaining designs are intended to be fixtures that can be offered as additional versions of typeface.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Typography",
    tags: ["Illustrator", "Typography", "DES 332B", "Design Course"],
    images: [
      require("./portfolio_data/10_number_set/0.jpg"),
      require("./portfolio_data/10_number_set/1.jpg"),
      require("./portfolio_data/10_number_set/2.jpg"),
      require("./portfolio_data/10_number_set/3.jpg"),
      require("./portfolio_data/10_number_set/4.jpg"),
    ],
  },
  {
    id: 5,
    title: "Tempo - Smino Cover Content",
    year: "2020",
    body:
      "In March of 2020, musician Smino asked fans to design him a cover for his upcoming single Tempo declaring he would pick a winner the following day. Within a couple of hours, I came up with the seen design, however, I was unfortunately not selected as a winner of the cover contest. ",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Album Art",
    tags: ["Photoshop", "Album Art", "Personal Project"],
    images: [require("./portfolio_data/7_tempo/0.jpg")],
  },
  {
    id: 4,
    title: "AAAI Club Posters",
    year: "2020",
    body:
      "In efforts to help bolster the image of my university’s Association for the Advancement of Artificial Intelligence branch, I helped to create graphics for a workshop and panel. Both posters were done in the style of other posters created FISK, one of my favorite design studios. Additionally, I lead the information portion of the perceptron workshop.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Poster Design",
    tags: ["Photoshop", "Poster Design", "Personal Project"],
    images: [
      require("./portfolio_data/6_aaai/0.jpg"),
      require("./portfolio_data/6_aaai/1.jpg"),
    ],
  },
  {
    id: 3,
    title: "Starstruck - Album Cover",
    year: "2020",
    body:
      "Inspired by some of Virgil Abloh’s early work, I used this project to take a shot at designing my first album cover. While the concept was never used, the skills I learned in this project would later inform my first actual album cover design for the same artist.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Album Art",
    tags: ["Illustrator", "Photoshop", "Album Art", "Personal Project"],
    images: [require("./portfolio_data/5_starstruck/0.jpg")],
  },
  {
    id: 2,
    title: "Microbe Typeface",
    year: "2020",
    body:
      "In my Typography I class, we were given the chance to design our own typeface. For this project, I worked with a gridded layout, essentially connecting the dots to create each letterform in Illustrator. I then used font-making software to turn the series of letters and numbers into a useable typeface.",
    linkURL: "microbe_otf",
    linkText: "Font Download (OTF)",
    linkType: "microbe_otf",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Typography",
    tags: ["Illustrator", "Typography", "DES 332A", "Design Course"],
    images: [require("./portfolio_data/4_microbe/0.jpg")],
  },
  {
    id: 1,
    title: "Piecework Project",
    year: "2020",
    body:
      "For the final project of my introductory design course, I created a series of designs that held the same base structure with each design differing in color. Each panel was created through 86 hand-cut bristol board pieces which were later painted with gouache to represent one of three color schemes (monochrome, primary, and complementary, respectively).",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Physical Design",
    tags: ["Physical Design", "DES 102", "Design Course"],
    images: [
      require("./portfolio_data/3_piecework/0.jpg"),
      require("./portfolio_data/3_piecework/1.jpg"),
      require("./portfolio_data/3_piecework/2.jpg"),
    ],
  },
  {
    id: 0,
    title: "Summer Film Series",
    year: "2019",
    body:
      "In the summer of 2019, I received my first film camera. This portfolio item is dedicated to my first two rolls of film with that camera, documenting a road trip from Denver to Los Angeles with my family and a day at Laguna beach with my friends.",
    linkURL: "",
    linkText: "",
    linkType: "href",
    secondaryLinkURL: "",
    secondaryLinkText: "",
    secondaryLinkType: "href",
    category: "Photography",
    tags: ["Photography", "Personal Project"],
    images: [
      require("./portfolio_data/2_summer_film/0.jpg"),
      require("./portfolio_data/2_summer_film/1.jpg"),
      require("./portfolio_data/2_summer_film/2.jpg"),
      require("./portfolio_data/2_summer_film/3.jpg"),
      require("./portfolio_data/2_summer_film/4.jpg"),
      require("./portfolio_data/2_summer_film/5.jpg"),
      require("./portfolio_data/2_summer_film/6.jpg"),
    ],
  },
];

export default projects;
